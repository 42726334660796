.projectsPage {
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("../Images/Museum_Nights_With_Her-1.png");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    color: white;
}

.projectsPageInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projectsLinkBox {
    position: sticky;
    bottom: 402px;
    width: 500px;
}

.kirbyGif {
    margin-top: 5%;
    height: 20%;
    width: 15%;
}

@media (width < 500px) {
    .projectsLinkBox {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h1 {
        font-size: 27px;
    }

    h2 {
        font-size: 17px;
        text-align: center;
    }

    .projectsLinkBox {
        position: sticky;
        bottom: 308px;
        width: 375px;
    }
  }