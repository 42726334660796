.projectLinksContainer {
    display: flex;
    align-items: center;
}

.projectLink {
    color: white;
    font-size: 24px;
    padding: 0px 10px;
}


@media (width < 500px) {
    .projectLink {
        color: white;
        font-size: 18px;
        margin: 70px 0px 0px 0px;
        padding: 5px 10px;
    }
}