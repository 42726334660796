.projectPage {
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("../Images/Museum_Nights_With_Her-1.png");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    color: white;
    overflow-x: hidden;
}

.projectContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 58vh;
}

.projectHeaderInfo {
    display: flex;
    align-items: center;
}

.projectImage {
    height: 31vh;
    width: 39vw;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 17%;
    width: 72%;
}

.sectionHeader, .projectTech, .sectionInfo {
    font-size: 16px;
    margin: 9px 0px 0px 0px;
}

@media (width < 500px) {
    .sectionInfo {
        font-size: 15px;
    }

    .projectContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 45vh;
    }

    .infoContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 43%;
        width: 92%;
    }
  }