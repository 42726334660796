.aboutMePage {
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("../Images/Museum_Nights_With_Her-1.png");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    color: white;
    overflow-x: hidden;
}

.aboutContent {
    height: 92vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.introduction {
    display: flex;
    align-items: center;
    width: 85%;
    border-bottom: 2px;
    border-color: white;
    border-bottom-style: groove;
}

.background {
    display: flex;
    flex-direction: row-reverse;
    width: 85%;
    height: 33%;
    align-items: center;
    border-bottom: 2px;
    border-color: white;
    border-bottom-style: groove;
}

.offTime {
    display: flex;
    align-items: center;
    width: 85%;
    border-bottom: 2px;
    border-color: white;
    border-bottom-style: groove;
}

p {
    text-align: justify;
    font-size: 17px;
  }

.zoomImage {
    height: 197px;
    width: 432px;
    float: right;
    margin: 5px;
}

.pharmImage {
    height: 238px;
    width: 145px;
    float: left;
    margin: 5px;
}

.escapeImage {
    height: 213px;
    width: 413px;
    float: right;
    margin: 5px;
}

@media (width < 500px) {
    .introduction {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 85%;
        border-bottom: 2px;
        border-color: white;
        border-bottom-style: groove;
    }

    .background {
        display: flex;
        width: 85%;
        height: 60%;
        align-items: center;
        border-bottom: 2px;
        border-color: white;
        border-bottom-style: groove;
    }

    .offTime {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 85%;
        border-bottom: 2px;
        border-color: white;
        border-bottom-style: groove;
    }

    p {
        text-align: justify;
        font-size: 15px;
      }
  }