.homepage {
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("../Images/Museum_Nights_With_Her-1.png");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    color: white;
}

.portfolioImage {
    height: 200px;
    width: 180px;
    clip-path: circle(45%);
}

#resume {
    height: 50px;
    width: 90px;
    font-size: 16px;
    border-radius: 10px;
    color: white;
    border-color: white;
    background-color: transparent;
    border-style: groove;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    text-decoration: none;
}

.iconContainer {
    display: flex;
    align-items: center;
}

.icons {
  margin: 0px 15px;
}

.icons:hover {
  transform: scale(1.2);
  transition: 0.2s ease-in
}

.intro {
  font-size: 24px;
}